import { FlavorConfig } from '../../types/flavor'
import { images } from './images'
import { BannerIcon } from './components/BannerIcon'

const config: FlavorConfig = {
  theme: {
    components: {
      banner: {
        backgroundColor: '#FEE000',
        textColor: '#002277',
        Icon: BannerIcon,
      },
    },
  },
  images,
}

export default config
