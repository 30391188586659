import { Flavor } from '@flavor'

import { default as Logo } from './Logo.svg'
import { default as AppleIcon } from './AppleIcon.svg'
import { default as GoogleIcon } from './GoogleIcon.svg'
import { default as TShirtPlaceholder } from './tshirt-placeholder.svg'
import { default as TShirtThumbnailBack } from './tshirt-thumbnail-back.svg'
import { default as TShirtThumbnailFront } from './tshirt-thumbnail-front.svg'
import { default as TShirtThumbnailRight } from './tshirt-thumbnail-right.svg'
import { default as TShirtThumbnailLeft } from './tshirt-thumbnail-left.svg'
import { default as Placeholder } from './placeholder.png'

export const Images = {
  Logo,
  AppleIcon,
  GoogleIcon,
  TShirtPlaceholder,
  TShirtThumbnailBack,
  TShirtThumbnailFront,
  TShirtThumbnailLeft,
  TShirtThumbnailRight,
  Placeholder,
  ...Flavor.images,
}
