import { default as LandingBackground } from './landing.jpg'
import { default as CreateAccountBackground } from './create-account-background.jpg'
import { default as ProfileBackground } from './bg_profile.jpg'
import { default as WalletBackground } from './bg_wallet.jpg'
import { default as ChallengesBackground } from './bg_challenges.jpg'
import { default as CollectiblesBackground } from './bg_collectibles.jpg'
import { default as DigitizeBackground } from './bg_digitize.jpg'
import { default as GalleryBackground } from './bg_gallery.jpg'
import { default as MarketplaceBackground } from './bg_marketplace.jpg'
import { default as PredictionsBackground } from './bg_predictions.jpg'
import { default as MuseumBackground } from './bg_museum.jpg'
import { default as TriviasBackground } from './bg_trivias.jpg'
import { default as PollsBackground } from './bg_poll.jpg'
import { images as baseTheme } from '../../shirtum/images'

export const images = {
  ...baseTheme,
  ProfileBackground,
  WalletBackground,
  LandingBackground,
  CreateAccountBackground,
  ChallengesBackground,
  CollectiblesBackground,
  DigitizeBackground,
  GalleryBackground,
  MarketplaceBackground,
  MuseumBackground,
  PredictionsBackground,
  TriviasBackground,
  PollsBackground,
}
