const globalStyles = `
  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Regular.otf') format("opentype");
  }
 
  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Bold-Italic.otf') format("opentype");
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Bold.otf') format("opentype");
    font-style: normal;
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Medium-Italic.otf') format("opentype");
    font-style: italic;
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Medium.otf') format("opentype");
    font-style: normal;
    font-weight: 500;
  }
  
   @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Regular-Italic.otf') format("opentype");
    font-style: italic;
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Regular.otf') format("opentype");
    font-style: normal;
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Light-Italic.otf') format("opentype");
    font-style: italic;
    font-weight: 300;
  }  
   
  @font-face {
    font-family: 'GT-Flexa';
    src: url('/fonts/gt-flexa/GT-Flexa-Standard-Light.otf') format("opentype");
    font-style: normal;
    font-weight: 300;
  }

`

export { globalStyles }
