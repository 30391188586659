import { default as LandingBackground } from './landing.jpg'
import { default as CreateAccountBackground } from './create-account-background.jpg'
import { default as CreateAccountImage } from './create-account.jpg'
import { default as ProfileBackground } from './bg_profile.jpg'
import { default as WalletBackground } from './bg_wallet.jpg'
import { default as ChallengesBackground } from './bg_community.jpg'
import { default as CollectiblesBackground } from './bg_collectibles.jpg'
import { default as DigitizeBackground } from './bg_digitize.jpg'
import { default as GalleryBackground } from './bg_gallery.jpg'
import { default as MarketplaceBackground } from './bg_marketplace.jpg'
import { default as PredictionsBackground } from './bg_predictions.jpg'
import { default as MuseumBackground } from './bg_museum.jpg'
import { default as TriviasBackground } from './bg_trivias.jpg'
import { default as PollsBackground } from './bg_community.jpg'
import { default as LoginImage } from './login.jpg'
import { default as SuccessImage } from './generic-success.jpg'
import { default as ErrorImage } from './generic-error.jpg'
import { default as PoolThumbnail } from './pool-thumbnail.jpg'
import { images as baseTheme } from '../../shirtum/images'

export const images = {
  ...baseTheme,
  SuccessImage,
  ErrorImage,
  LoginImage,
  ProfileBackground,
  WalletBackground,
  LandingBackground,
  CreateAccountBackground,
  CreateAccountImage,
  ChallengesBackground,
  CollectiblesBackground,
  DigitizeBackground,
  GalleryBackground,
  MarketplaceBackground,
  MuseumBackground,
  PredictionsBackground,
  TriviasBackground,
  PollsBackground,
  PoolThumbnail,
}
