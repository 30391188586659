import Box from '@mui/material/Box'
// eslint-disable-next-line import/order
import { keyframes } from '@emotion/react'

// eslint-disable-next-line import/order
import { Flavor } from '@flavor'
import { TextH4Bold } from '@components'
import { useTranslation } from '@hooks'

const scrolling = keyframes`
    0% { transform: translateX(100vw)}
    100% { transform: translateX(0)}
`
const Banner = (props: { scrollEnabeld?: boolean }) => {
  const { scrollEnabeld = false } = props
  const { t } = useTranslation()
  const backgroundColor = Flavor.theme.components.banner.backgroundColor
  const textColor = Flavor.theme.components.banner.textColor
  const BannerIcon = Flavor.theme.components.banner.Icon

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor,
        justifyContent: scrollEnabeld ? undefined : 'center',
      }}
    >
      <Box
        sx={theme => ({
          padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
          display: 'flex',
          animation: scrollEnabeld ? `${scrolling} 15s linear infinite` : undefined,
          width: scrollEnabeld ? 'auto' : '100%',
          justifyContent: 'space-between',
          gap: scrollEnabeld ? 10 : 0,
        })}
      >
        <Box component={'span'} sx={{ height: 20, marginTop: -0.5, color: textColor }}>
          <BannerIcon size={24} />
        </Box>
        <TextH4Bold
          sx={{
            color: textColor,
            textAlign: 'center',
          }}
        >
          {t('landing.banner')}
        </TextH4Bold>
        <Box component={'span'} sx={{ height: 20, marginTop: -0.5, color: textColor }}>
          <BannerIcon size={24} />
        </Box>
      </Box>
    </Box>
  )
}

export { Banner }
