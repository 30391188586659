import { FlavorConfig } from '../../types/flavor'
import { BannerIcon } from '../shirtum/components/BannerIcon'
import { images } from './images'

const config: FlavorConfig = {
  theme: {
    components: {
      banner: {
        backgroundColor: '#E30A17',
        textColor: '#FFFFFF',
        Icon: BannerIcon,
      },
    },
  },
  images,
}

export default config
