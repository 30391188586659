import { FlavorConfig } from '../../types/flavor'
import { BannerIcon } from './components/BannerIcon'
import { images } from './images'

const config: FlavorConfig = {
  theme: {
    components: {
      banner: {
        backgroundColor: '#D2FF00',
        textColor: '#000000',
        Icon: BannerIcon,
      },
    },
  },
  images,
}

export default config
